import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Faq = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section faq-container",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const faqs = [
    {
      question: "Who is Life Story best suited for?",
      answer:
        "While anyone can have their Life Story told, senior citizens have a more complete story to tell, so are probably the best fit for the product. The Star is required to answer questions about their life, so unfortunately it’s not suitable for someone with advanced dementia or a seriously diminished capacity to recall their life’s events. The product is also appropriate for terminally ill people who are still in a condition to spend several hours talking on camera, albeit over several sessions, who would like to create something for their young children to look back on.",
    },
    {
      question: "What do I need to provide?",
      answer:
        "Your Life Story requires you to provide a project manager who is responsible for:-\nArranging the collection and uploading of project media (photos and videos) \nIdentifying subjects in photos using our media tagging software\nOrganising/filming interviews with “The Star”\nChecking for and providing project updates using our project management software    ",
    },
    {
      question: "How do I upload and label photos?",
      answer:
        "Life Story provides a shared folder for you and your family to upload images and video to. We have proprietary software that allows you to easily label the subjects in your photos in a few mouse clicks, making the process of collecting and labelling photos very simple.",
    },
    {
      question: "What equipment do I need?",
      answer:
        "The project manager requires an internet connected computer, and some basic computer skills to perform their tasks. Filming “The Star” requires a digital camera or mobile phone with adequate storage to record 1-2 hours of interview footage, and a location which is well-lit and quiet. We provide advice on additional lighting and sound equipment you may wish to purchase, although this is not necessary.",
    },
    {
      question: "What's involved in interviewing “The Star”?",
      answer:
        "“The Star” will need to answer many questions about their life, contained in an interview script that will be provided to you. You can provide the script to your “Star” in the days leading up to the interview, so they can think about their answers. The total interview process will take 2-3 hours, and can be shot in a single interview day (if there are adequate breaks), however to avoid fatigue we recommend shooting over several sessions.",
    },
    {
      question:
        "Do you offer refunds should “The Star” be unable to complete the project?",
      answer:
        "As many of our “Stars” are elderly or perhaps experiencing ill-health, we provide a 50% refund should the “Star” be unable to complete the interview process.",
    },
    {
      question: "How long is the movie?",
      answer:
        "This depends on the length of the answers that your “Star” provides, and how many photos and videos we have to work with. The final production may be as short as 20 minutes if your “Star” doesn’t have long stories to tell, or up to 60 minutes if they’re quite talkative.",
    },
    {
      question: "Can I request changes to the movie?",
      answer:
        "Yes. Once the production is ready, you’re able to make change requests if there is anything that is wrong, or you’re not happy with. We want everything to be accurate and only reveal what you’re comfortable with.",
    },
    {
      question: "How do I get started?",
      answer:
        "We require our clients to complete an application form before we begin, which will be followed by a brief phone conversation with your project manager. That way we can get to know you, learn about your “Star”, and make sure everyone is on the same page before we kick-off your project.",
    },
  ];

  return (
    <section {...props} className={outerClasses}>
      <div className={innerClasses}>
        <div className="hero-content">
          <h1
            className="mt-0 mb-16 reveal-from-bottom faq-title"
            data-reveal-delay="200"
          >
            FAQs
          </h1>
          <div className="tabs">
            {faqs.map((faq, i) => {
              return (
                <div className="tab" key={i}>
                  <input
                    className="terms-check"
                    type="checkbox"
                    id={"chck" + i}
                  />
                  <label className="tab-label" htmlFor={"chck" + i}>
                    {faq.question}
                  </label>
                  <div className="tab-content">
                    <p>{faq.answer}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

Faq.propTypes = propTypes;
Faq.defaultProps = defaultProps;

export default Faq;
