import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Image from "../elements/Image";
import Modal from "../elements/Modal";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
  const classes = classNames(
    "site-footer center-content-mobile",
    topOuterDivider && "has-top-divider",
    className
  );

  const [showForm, setShowForm] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);

  const showModal = (e) => {
    e.preventDefault();
    setShowForm(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setShowForm(false);
  };
  const showPolicyModal = (e) => {
    e.preventDefault();
    setShowPolicy(true);
  };

  const closePolicyModal = (e) => {
    e.preventDefault();
    setShowPolicy(false);
  };
  return (
    <footer {...props} className={classes}>
      <div className="container">
        <div
          className={classNames(
            "site-footer-inner flex justify-between",
            topDivider && "has-top-divider"
          )}
        >
          <div className="footer-bottom space-between text-xxs">
            <div className="flex column">
              <Image
                src={require("./../../assets/images/Logo-Footer.png")}
                alt="Open"
                width={149}
                height={44}
              />
              <div className="footer-copyright">
                © {new Date().getFullYear()}, All rights reserved.
              </div>
            </div>
          </div>
          <div className="footer-bottom space-between text-xxs invert-order-desktop">
            <div>
              <span onClick={showPolicyModal} className="pointer">
                Privacy Policy
              </span>
            </div>
            <div>
              <span onClick={showModal} className="pointer">
                Terms and conditions
              </span>
              <span className="term-bar">&nbsp;{" | "}</span>
              &nbsp;
            </div>
          </div>
        </div>
      </div>

      <Modal
        id="video-modal"
        show={showPolicy}
        handleClose={closePolicyModal}
        className={"termsModal"}
      >
        <div className="terms-title">Privacy Policy</div>
        <div>
          <p>
            In addition to those provisions under the Life Story Privacy Policy,
            Life Story will disclose relevant personal information collected
            about you when you place this order to the third party suppliers for
            the purposes of providing order information. The third party
            supplier has agreed that such information shall not be used except
            for the purposes of fulfilling the order to the Life Story customer.
            The third party supplier will retain such relevant information as is
            necessary for the purposes of a warranty claim. Upon expiry of such
            a time, the information will be returned to Life Story and managed
            in accordance with the Life Story Privacy Policy, described herein.
          </p>
        </div>
      </Modal>

      <Modal
        id="terms-modal"
        show={showForm}
        handleClose={closeModal}
        className={"termsModal"}
      >
        <div className="terms-title">Terms and conditions</div>
        <div>
          <p>
            The material on the Life Story trading as ‘Life Story Movie’ ABN
            53169416817 Website, under the domain name ‘lifestorymovie.com' and
            resides on a server in the state of New South Wales, Australia. This
            Website is offered to you conditional upon your acceptance of these
            Terms of Use and the associated Privacy Policy. Your use of and
            access to this Website constitutes your acceptance.
          </p>
          <p>
            We may change the Terms of Use at our discretion and without notice.
            A version of the current Terms of Use will be available on this
            Website as they are changed. By continuing to use our Website you
            accept the Terms of Use as they apply from time to time.
          </p>
          <p>
            If you access this Website from outside Australia you are also
            responsible for ensuring compliance with all applicable laws in the
            place where you are located.
          </p>

          <h3>1. Anti-spam notice</h3>
          <p>
            Publication of electronic addresses on Life Story's Website is for
            the purpose of professional communication only and must not be used
            to send unsolicited commercial electronic messages.
          </p>
          <h3>2. Content on this Website </h3>
          <p>
            Material or information (‘Content') on this Website is not advice
            and is subject to periodic changes and may include inaccuracies.
            Life Story does not warrant the accuracy, completeness or adequacy
            of the content in this Website. You should make your own enquiries
            before entering any transaction on the basis of the content on this
            Website.
          </p>
          <h3>3. External links and advertisements</h3>
          <p>
            This Website contains links to websites maintained by other
            organisations over which Life Story has no control. Life Story does
            not necessarily sponsor, endorse, or approve any material or
            advertising on external sites or the operations of external sites.
            You access those external sites and use their information, products
            and services solely at your own risk. Life Story makes no warranties
            or representations regarding the quality, accuracy, merchantability
            or fitness for purpose of any material or advertising on external
            sites to which this Website is linked, nor is Life Story responsible
            for the availability of these external links.
          </p>
          <h3>4. Limitation of liability</h3>
          <p>
            Life Story (i) makes no warranties or representations about this
            Website or any of the Content; (ii) accepts no responsibility for
            any loss suffered by you or anyone else in connection with the use
            of this Website or any of the Content; (iii) does not guarantee that
            this website will not cause damage or is free from viruses or any
            other defects or errors. Life Story excludes, to the maximum extent
            permitted by law, any liability which may arise as a result of the
            use of this Website or the Content on it. Where liability cannot be
            excluded, any liability incurred by Life Story in relation to the
            use of this website or the Content, is limited as provided under the
            Competition and Consumer Act 2010 (Cth) sch 2, s 64A.{" "}
          </p>
          <p>
            Under no circumstances will Life Story be liable for any incidental,
            special or consequential damages, including damages for loss of
            business or other profits arising in relation to the Content. This
            includes, but is not limited to, the transmission of any computer
            virus or any other defects or errors. Life Story shall not be held
            responsible or liable for any direct or indirect loss or damages
            caused or alleged to have been caused by your use, of, or reliance
            on, any content, goods or services available on external sites. Any
            concerns regarding an external link, should be directed to its
            responsible site administrator or webmaster.
          </p>
          <h3>5. Copyright in material on this site and restriction of use</h3>
          <p>
            Save and except for any third party copyright included on this
            website under a licence or agreement, all the Content of this
            Website and downloads from it, is owned by Life Story trading as
            ‘Life Story’ (ABN 51 131 422 403). All rights reserved. Other than
            for the purposes of and subject to the conditions prescribed under
            the Copyright Act 1968 (Cth) (or any other applicable legislation
            throughout the world), or as otherwise provided for in this
            copyright notice, no part of any Content may in any form or by any
            means (including electronic, mechanical, microcopying, photocopying
            or recording) be reproduced, adapted, stored in a retrieval system,
            transmitted or reproduced on any other Internet website without the
            prior written permission of the copyright owner. For further
            information about copyright in Life Story content contact the Legal
            Business Unit. Life Story grants you permission to do the following
            with the content unless otherwise indicated:
            <ul className="mt-8">
              <li>
                store a reproduction of the content on your local computer for
                the sole purpose of viewing it
              </li>
              <li>
                store a reproduction of the content on your local computer for
                the sole purpose of viewing it
              </li>
              <li>
                print hard copies of the content for non-commercial use or
                personal use only
              </li>
              <li>
                reproduce or distribute any complete page of the content so long
                as
                <ul className="mt-8">
                  <li>
                    you do not reproduce or redistribute less than a complete
                    page, or, where a document consists of more than one page,
                    less than the complete document
                  </li>
                  <li>
                    you do not modify the copy from how it appears on the
                    Website (unless expressly authorised by Life Story)
                  </li>
                  <li>
                    you adhere to the Permitted Use Statement for Standards,
                    Guidelines and Resources available on this website, where
                    applicable, and
                  </li>
                  <li>the content is appropriately attributed to Life Story</li>
                </ul>
              </li>
            </ul>
          </p>
          <p>
            Without limiting the foregoing, you must not reproduce, frame,
            transmit (including broadcast), adapt, link to, or otherwise use any
            of the content on the website including audio and video excerpts
            without the prior written approval of Life Story.
          </p>
          <h3>6. Copyright in other material</h3>
          <p>
            Life Story makes no warranties or representations that third party
            material on this Website or material on other websites to which this
            Website is linked, does not infringe the intellectual property
            rights of any person anywhere in the world.
          </p>
          <p>
            Life Story is not, and must not be taken to be, authorising
            infringement of any intellectual property rights contained in
            material on other sites by linking this Website to such material on
            other sites.
          </p>
          <h3>7. Trademarks</h3>
          <p>
            Trademarks including but not limited to the words ‘Life Story Movie'
            and ‘Life Story’, unless otherwise indicated, are owned by Life
            Story in Australia and may be the subject of rights in other
            countries. You must not use Life Story's trademarks except: (i) by
            saving or printing out a copy of Life Story's website as permitted
            by the copyright notice above; (ii) as permitted by written
            agreement from Life Story.
          </p>
          <p>
            This Website may contain trademarks or logos of other companies or
            organisations and these are proprietary to the registered owner(s)
            of such marks.
          </p>
          <h3>8. Indemnification</h3>
          <p>
            You agree to indemnify, defend and hold harmless Life Story, its
            officers, employees, and agents from any loss, liability, claim,
            demand, damage or expenses (including legal costs on a full
            indemnity basis) asserted by any third party due to or arising from
            or in connection with your use of Content, or your conduct on this
            Website, or messages posted or transmitted by you on or through this
            Website. Life Story reserves the right, at its own expense, to
            assume the exclusive defence and control of any matter otherwise
            subject to indemnification by you, which shall not excuse your
            indemnification obligations.
          </p>
          <h3>9. Invalid terms and conditions</h3>
          <p>
            In the event that any or any part of the terms contained in these
            Terms of Use are determined by a court to be invalid, unlawful or
            unenforceable, then such term/s shall be severed from the remaining
            terms.
          </p>
          <h3>10. Variation of terms of use</h3>
          <p>
            Life Story reserves the right, at its sole discretion, to change,
            modify, add or delete portions of these Terms of Use at any time and
            you should therefore periodically review this page.
          </p>
          <h3>11. Website unavailability</h3>
          <p>
            Life Story does not accept responsibility in the event that the
            website is unavailable to you due to any computer downtime,
            malfunction or such other event outside the control of Life Story.
          </p>
          <h3>12. Payment</h3>
          <p>
            Your payment must be made in accordance with the payment terms
            agreed upon when ordering Life Story. We reserve our right to refuse
            continuation of production if payment is not made prior to the
            project stage reached.
          </p>
          <h3>12.1 Payment terms</h3>
          <p>
            A Link to our third party payment provider (‘Stripe’) will be
            issued, and all payments will be processed by that vendor. A Tax
            Invoice will be issued once processing has been completed. Any
            dispute regarding the invoice must be notified to Life Story within
            two (2) business days of receipt of invoice. No product or service
            shall be delivered to you without payment being received by Life
            Story.
          </p>
          <h3>12.2 Payment types</h3>
          <p>
            The following credit cards are accepted:
            <ul className="mt-8">
              <li>Visa</li>
              <li>Mastercard</li>
            </ul>
          </p>
          <h3>13. Prices</h3>
          <p>
            Prices are correct at the time they appear on this Website. However,
            Life Story reserves the right to change prices at any time. Should
            you choose an instalment plan, the price for any Life Story Movie
            for which a payment has already been processed will not be subject
            to increase.
          </p>
          <p>
            The prices appearing on this Website may have a GST component and
            you are encouraged to check this at point of payment, otherwise the
            prices include processing, handling, insurance and delivery charges
            for delivery within Australia.
          </p>
          <p>
            In order to obtain a GST exclusive price, you may be asked to
            declare your residential status, physical location and use of the
            supply of goods or services you purchase on this Website in the
            following form: I declare that (i) I am not an Australian resident;
            and (ii) I am not, or will not be, in Australia at the time I
            receive the goods or services. Please note that your transaction
            will not be completed unless you make this declaration when
            prompted.
          </p>
          <p>
            The GST exclusive price is only available to customers who:
            <ul className="mt-8">
              <li>are non residents</li>
              <li>
                are outside Australia at the time they receive the supply of the
                their Life Story Movie
              </li>
              <li>agree to the declaration referred to previously</li>
            </ul>
          </p>
          <h3>14. Refund Policy</h3>
          <p>
            It is important to Life Story that all clients understand their
            rights and obligations in relation to refunds.
            <ul className="mt-8">
              <li>
                Life Story is a non-refundable fee once you finalise the
                submission of interview footage (video)
              </li>
              <li>
                Should you be unable to complete the interview process due to
                sickness or death, you may contact Life Story via email and
                request a refund, to which a 50% refund of the full sale amount
                will apply. The full sale amount is the sum of all applicable
                instalment amounts.
              </li>
            </ul>
          </p>
          <p>
            NOTE: If your refund request is approved you may be required to
            provide your original registration/membership documents before your
            refund can be processed.
          </p>
        </div>
      </Modal>
    </footer>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
