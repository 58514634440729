import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Image from "../elements/Image";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Inclusions = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section inclusion-container",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const inclusions = [
    {
      image: "Icon-VideoProduction.svg",
      text: 'A "Life Story" video production of your star (20-60 mins).',
    },
    {
      image: "Icon-CloudStorage.svg",
      text: "Lifetime cloud storage of the movie.",
    },
    {
      image: "Icon-Views.svg",
      text: "Unlimited views of the movie by family and friends.",
    },
  ];

  return (
    <section {...props} className={outerClasses}>
      <div className={innerClasses}>
        <div className="hero-content">
          <h1
            className="reveal-from-bottom inclusion-title"
            data-reveal-delay="200"
          >
            Inclusions
          </h1>
          <div className="grid-3">
            {inclusions.map((data, i) => {
              return (
                <div key={i}>
                  <div className="reveal-from-bottom inclusion-image">
                    <Image
                      className={"m-auto"}
                      src={require("./../../assets/images/" + data.image)}
                      alt="involved"
                    />
                  </div>
                  <p className="inclusion-p reveal-from-bottom">{data.text}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

Inclusions.propTypes = propTypes;
Inclusions.defaultProps = defaultProps;

export default Inclusions;
