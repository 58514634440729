import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Image from "../elements/Image";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Involved = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section involved-container",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const involves1 = [
    {
      image: "icon-Star.svg",
      text: "Choose your “Star” and provide basic information about them, their family and friends.",
    },
    {
      image: "icon-photo.svg",
      text: "Upload and label images of your Star and their loved ones.",
    },
    {
      image: "icon-Script.svg",
      text: "Follow a scripted interview, recording your Star's responses on camera.",
    },
  ];

  const involves2 = [
    {
      image: "icon-Watch.svg",
      text: "Upload the video footage and let our production team do the rest.",
    },
    {
      image: "Icon-Feedback.svg",
      text: "Provide final feedback, and once approved we’ll provide a link to the movie you can share.",
    },
  ];

  return (
    <section {...props} className={outerClasses}>
      <div className={innerClasses}>
        <div className="hero-content">
          <h1
            className="reveal-from-bottom involved-title"
            data-reveal-delay="200"
          >
            What’s involved?
          </h1>

          <div className="flex justify-center involvedContentWrapper">
            <div>
              {involves1.map((data, i) => {
                return (
                  <div className="flex involved-item" key={i}>
                    <Image
                      className="reveal-from-bottom"
                      src={require("./../../assets/images/" + data.image)}
                      alt="involved"
                    />
                    <p className="involved-p reveal-from-bottom">{data.text}</p>
                  </div>
                );
              })}
            </div>
            <div>
              {involves2.map((data, i) => {
                return (
                  <div className="flex involved-item" key={i}>
                    <Image
                      className="reveal-from-bottom"
                      src={require("./../../assets/images/" + data.image)}
                      alt="involved"
                    />
                    <p className="involved-p reveal-from-bottom">{data.text}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Involved.propTypes = propTypes;
Involved.defaultProps = defaultProps;

export default Involved;
