import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Input from "../elements/Input";
import Modal from "../elements/Modal";
import ReCAPTCHA from "react-google-recaptcha";

// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import toast, { Toaster } from "react-hot-toast";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const [showForm, setShowForm] = useState(false);
  const [verified, setVerified] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [enquiry, setEnquiry] = useState("");
  const [nameHint, setNameHint] = useState("");
  const [emailHint, setEmailHint] = useState("");
  const [enquiryHint, setEnquiryHint] = useState("");
  const [captchaHint, setCaptchaHint] = useState("");

  const showModal = (e) => {
    e.preventDefault();
    setShowForm(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setShowForm(false);
  };
  const recaptchaRef = useRef(null);

  var verifyCallback = function (response) {
    if (response) {
      setVerified(true);
      setCaptchaHint("");
    }
  };

  const handleChange = (e) => {
    if (e.target.id === "name") {
      setName(e.target.value);
      setNameHint("");
    } else if (e.target.id === "email") {
      setEmail(e.target.value);
      setEmailHint("");
    } else if (e.target.id === "enquiry") {
      setEnquiry(e.target.value);
      setEnquiryHint("");
    }
  };

  const handleSubmit = async (e) => {

    if (name === "") {
      setNameHint("Please enter your name");
      return;
    }
    if (email === "") {
      setEmailHint("Please enter your email address");
      return;
    }
    if (enquiry === "") {
      setEnquiryHint("Please enter your enquiry");
      return;
    }
    if (!verified) {
      setCaptchaHint("Please complete the captcha");
      return;
    }
    // e.preventDefault();
    const token = recaptchaRef.current.getValue();
    recaptchaRef.current.reset(); 

    let variables = {
      message: enquiry,
      from_name: name,
      reply_to: email,
    };

    window.emailjs
      .send("service_wibr439", "template_fasb1vq", variables)
      .then((res) => {
        toast.success('Email successfully sent!')
        setVerified(false);
      })
      .catch((err) => {
        setVerified(false);
        toast.error("Couldn't send an email.")
        console.error("Failed:", err);
      });
  };

  const outerClasses = classNames(
    "section center-content-mobile reveal-from-bottom contact-container",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "contact-right",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="flex">
        <div className={"contact-left"}>
          <h3 className="contact-title">Ready to tell someone’s life story?</h3>
          <div>
            <button onClick={showModal} className="btnLearnMore">
              Apply Now
            </button>
          </div>
          <div className="contact-line"></div>
          <form id="contact-form">
            <input type="hidden" name="contact_number" />
            <p className="contact-p">
              If you have more questions, please submit your enquiry below…
            </p>
            <div className="flex mb-16">
              <div className="cta-action">
                <Input
                  id="name"
                  type="text"
                  label="Name"
                  labelHidden
                  placeholder="NAME"
                  className={"contact-input"}
                  value={name}
                  hint={nameHint}
                  onChange={handleChange}
                  name="user_name"
                ></Input>
              </div>
            </div>

            <div className="flex mb-8">
              <div className="cta-action">
                <Input
                  id="email"
                  type="email"
                  label="Email"
                  labelHidden
                  placeholder="EMAIL"
                  className={"contact-input"}
                  value={email}
                  hint={emailHint}
                  onChange={handleChange}
                  name="user_email"
                ></Input>
              </div>
            </div>

            <div className="mt-16">
              <div className="cta-action">
                <Input
                  id="enquiry"
                  type="textarea"
                  label="Subscribe"
                  labelHidden
                  placeholder="YOUR ENQUIRY"
                  className={"contact-input"}
                  value={enquiry}
                  hint={enquiryHint}
                  onChange={handleChange}
                  name="message"
                ></Input>
              </div>
            </div>
            <div className="cpt-captcha">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={"6LdR0t0iAAAAAF3drRZXUjgbaXe0NI3Fj8gx_Ch-"}
                onChange={verifyCallback}
                // size="invisible"
              />
              <div className="form-hint">{captchaHint}</div>
            </div>
            <div className="flex mt-0 justify-center">
              <button
                className="btnLearnMore mt-1"
                onClick={() => handleSubmit(false)}
                type="button"
              >
                Submit
              </button>
            </div>
          </form>
        </div>

        <div className={innerClasses}></div>
      </div>
      <Modal
        id="contact-modal"
        show={showForm}
        video={
          "https://forms.monday.com/forms/embed/f88029af3831240ce075104dfee2601a?r=use1"
        }
        handleClose={closeModal}
        videoTag="iframe"
      />
      <Toaster position="bottom-center" reverseOrder={false} />
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
