import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Modal from "../elements/Modal";
import Image from "../elements/Image";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Production = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);
  const [videoModalActive2, setVideomodalactive2] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };
  const openModal2 = (e) => {
    e.preventDefault();
    setVideomodalactive2(true);
  };

  const closeModal2 = (e) => {
    e.preventDefault();
    setVideomodalactive2(false);
  };
  const outerClasses = classNames(
    "hero section production-container",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className={innerClasses}>
        <div className="hero-content">
          <h1
            className="reveal-from-bottom production-title"
            data-reveal-delay="200"
          >
            The production
          </h1>
          <p
            className="production-desc reveal-from-bottom"
            data-reveal-delay="400"
            style={{ fontWeight: 300 }}
          >
            Have you ever seen the television show "This is Your Life"? Well, <b>Life Story</b> is the "This is Your Life" for the everyday person. We
            guide you through the process of providing all the media we need.
            Our talented videographers then bring it all together, and upon your
            final approval, publish the movie to a secure cloud storage
            location, giving your current family and future generations the
            opportunity to learn about the life of someone special, both now and
            beyond the grave. Here are a couple of examples of what to expect…
          </p>
        </div>
        <div className="flex justify-between video-wrapper">
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            <Image
              className=""
              src={require("./../../assets/images/JohnMoroneyTitle.jpg")}
              alt="VideoSection"
              width={576}
              height={350}
            />
            <a
              className="video-player"
              data-video="https://player.vimeo.com/video/759752850?h=03b0655aa6"
              href="#0"
              aria-controls="video-modal2"
              onClick={openModal2}
            >
              <Image
                className=""
                src={require("./../../assets/images/icon-videoplay.png")}
                alt="VideoSection"
                width={101}
                height={101}
              />
            </a>
            <div className="video-label-wrapper">
              <label className="video-label">John Moroney</label>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            <Image
              className=""
              src={require("./../../assets/images/PegLovedayTitle.jpg")}
              alt="VideoSection"
              width={576}
              height={350}
            />
            <a
              className="video-player"
              data-video="https://player.vimeo.com/video/756299796?h=546233bd16"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className=""
                src={require("./../../assets/images/icon-videoplay.png")}
                alt="VideoSection"
                width={101}
                height={101}
              />
            </a>
            <div className="video-label-wrapper">
              <label className="video-label">Margaret Loveday</label>
            </div>
          </div>
        </div>

        <Modal
          id="video-modal"
          show={videoModalActive}
          handleClose={closeModal}
          video="https://player.vimeo.com/video/756299796?h=546233bd16&autoplay=1"
          videoTag="iframe"
        />

        <Modal
          id="video-modal2"
          show={videoModalActive2}
          handleClose={closeModal2}
          video="https://player.vimeo.com/video/759752850?h=03b0655aa6&autoplay=1"
          videoTag="iframe"
        />
      </div>
    </section>
  );
};

Production.propTypes = propTypes;
Production.defaultProps = defaultProps;

export default Production;
