import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Image from "../elements/Image";
import Modal from "../elements/Modal";

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  const [isActive, setIsactive] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const nav = useRef(null);
  const hamburger = useRef(null);

  // useEffect(() => {
  //   isActive && openMenu();
  //   document.addEventListener("keydown", keyPress);
  //   document.addEventListener("click", clickOutside);
  //   return () => {
  //     document.removeEventListener("keydown", keyPress);
  //     document.removeEventListener("click", clickOutside);
  //     closeMenu();
  //   };
  // });

  const showModal = (e) => {
    e.preventDefault();
    setShowForm(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setShowForm(false);
  };

  const openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    nav.current.style.maxHeight = nav.current.scrollHeight + "px";
    console.log("oten menu...", nav);
    setIsactive(true);
  };

  const closeMenu = () => {
    setTimeout(() => {
      const urlHash = window.location.hash;
      if (urlHash.length) {
        const element = document.getElementById(urlHash.substring(1));
        if (element) {
          element.scrollIntoView();
        }
      }
      console.log("close....", urlHash);
    }, 300);
    // document.body.classList.remove("off-nav-is-active");
    // nav.current && (nav.current.style.maxHeight = null);
    // setIsactive(false);
    // openMenu()
  };

  // const keyPress = (e) => {
  //   isActive && e.keyCode === 27 && closeMenu();
  // };

  // const clickOutside = (e) => {
  //   if (!nav.current) return;
  //   if (
  //     !isActive ||
  //     nav.current.contains(e.target) ||
  //     e.target === hamburger.current
  //   )
  //     return;
  //   closeMenu();
  // };

  const classes = classNames(
    "header-container",
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  return (
    <header {...props} className={classes}>
      <Image
        src={require("./../../assets/images/logo-Header.png")}
        className={"logo"}
        alt="Open"
        width={135}
        height={117}
      />
      <div className="container menu-container">
        <div
          className={classNames(
            "site-header-inner",
            bottomDivider && "has-bottom-divider"
          )}
        >
          {!hideNav && (
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={classNames("header-nav", isActive && "is-active")}
              >
                <div className="header-nav-inner">
                  <ul
                    className={classNames(
                      "list-reset text-xs",
                      navPosition && `header-nav-${navPosition}`
                    )}
                  >
                    <li>
                      <Link
                        to="#story"
                        onClick={closeMenu}
                        className={"menu-title"}
                      >
                        About
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#production"
                        onClick={closeMenu}
                        className={"menu-title"}
                      >
                        Production
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#involved"
                        onClick={closeMenu}
                        className={"menu-title"}
                      >
                        Whats involved
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#inclusion"
                        onClick={closeMenu}
                        className={"menu-title"}
                      >
                        Inclusions
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#faq"
                        onClick={closeMenu}
                        className={"menu-title"}
                      >
                        FAQs
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#pricing"
                        onClick={closeMenu}
                        className={"menu-title"}
                      >
                        Pricing
                      </Link>
                    </li>
                    <li>
                      <span
                        onClick={showModal}
                        className={"menu-title"}
                        style={{
                          fontWeight: "500",
                          padding: "0 18px",
                          cursor: "pointer",
                        }}
                      >
                        Apply
                      </span>
                    </li>
                    <li>
                      <Link
                        to="#cta"
                        onClick={closeMenu}
                        className={"menu-title"}
                      >
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
      <div className="header-title-wrapper">
        <p className="header-title">The Story of</p>
        <p className="header-title">a Lifetime</p>
        <Link to="#story" onClick={closeMenu}>
          <button className="btnLearnMore btnLearnMoreHome">Learn More</button>
        </Link>
      </div>

      <Modal
        id="video-modal"
        show={showForm}
        video={
          "https://forms.monday.com/forms/embed/f88029af3831240ce075104dfee2601a?r=use1"
        }
        handleClose={closeModal}
        videoTag="iframe"
      />
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
