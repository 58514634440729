import React from "react";
import TheStory from "../components/sections/TheStory";
import Production from "../components/sections/Production";
import Involved from "../components/sections/Involved";
import Inclusions from "../components/sections/Inclusions";
import Faq from "../components/sections/Faq";
import Pricing from "../components/sections/Pricing";
import Cta from "../components/sections/Cta";

const Home = () => {
  return (
    <>
      <TheStory id="story" />
      <Production id="production" />
      <Involved id="involved" />
      <Inclusions id="inclusion" />
      <Faq id="faq" />
      <Pricing id="pricing" />
      <Cta id="cta" />
    </>
  );
};

export default Home;
