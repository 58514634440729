import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Image from "../elements/Image";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const TheStory = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section center-content thestorycontainer",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="flex">
        <div className="thestory-image">
          <Image
            src={require("./../../assets/images/Img_About.png")}
            className={""}
            alt="Open"
            width={636}
          />
        </div>
        <div className={"thestorytext"}>
          <p className="reveal-from-bottom">How will your loved ones be remembered when they’re gone?</p>
          <p className="reveal-from-bottom">How will future generations know who their ancestors were?</p>
          <p className="reveal-from-bottom">
            Scattered photos and videos provide some memories, but don't capture
            the full story…
          </p>
          <p className="reveal-from-bottom" style={{ marginTop: "42px" }}>
            <b>Life Story</b> documents the life of someone special into a
            professional 20-60 minute video production. The movie is stored in
            the cloud eternally and offers unlimited views by friends and
            family, ensuring your loved one’s legacy is never lost or forgotten.
          </p>
        </div>
      </div>
    </section>
  );
};

TheStory.propTypes = propTypes;
TheStory.defaultProps = defaultProps;

export default TheStory;
