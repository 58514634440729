import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Pricing = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section pricing-contaienr",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className={innerClasses}>
        <div className="hero-content">
          <h1
            className="mt-0 mb-16 reveal-from-bottom pricing-title"
            data-reveal-delay="200"
          >
            Pricing
          </h1>

          <p
            className="pricing-desc reveal-from-bottom"
            style={{ fontWeight: 300 }}
          >
            A production of this quality would normally cost multiple thousands,
            however Life Story has been priced to make this amazing product
            something that regular families can afford. Flexible payment options
            also allow you to split the cost between family members, so everyone
            can contribute towards this perfect gift for a parent or
            grandparent.
          </p>
          <div className="pricing-table-content">
            <table className="pricing-table">
              <thead>
                <tr>
                  <th
                    className="center"
                    style={{ borderTopLeftRadius: "10px", paddingRight: 0 }}
                  >
                    # INSTALLMENTS
                  </th>
                  <th align="center" style={{ padding: "8px 0" }}>
                    $AUD/each
                  </th>
                  <th
                    align="left"
                    style={{
                      borderTopRightRadius: "10px",
                      textAlign: "left",
                      paddingLeft: "32px",
                    }}
                  >
                    TERMS
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="first-row">
                  <td>1</td>
                  <td>$495</td>
                  <td className="td-terms" style={{ display: "flex" }}>
                    <div style={{ marginTop: "10px" }}>
                      1 x up-front payment (total cost $495){" "}
                    </div>
                    <div className="m-auto" style={{ marginTop: "4px" }}>
                      <span className="bestvalue">Best Value</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>$270</td>
                  <td className="td-terms">
                    2 x monthly payments (total cost $540)
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>$195</td>
                  <td className="td-terms">
                    3 x monthly payments (total cost $585)
                  </td>
                </tr>
                <tr className="last-row">
                  <td>6</td>
                  <td>$125</td>
                  <td className="td-terms">
                    6 x monthly payments (total cost $750){" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;

export default Pricing;
